const STAGING1 = "staging1";
const STAGING2 = "staging2";
const PRODUCTION = "production";
const EPHEMERAL = "ephemeral";
const LOCAL = "local";

export function getReleaseStage(): string {
  if (typeof window === "undefined") {
    return "";
  }
  const host = window.location.host;
  if (host.includes("sts.upstart")) {
    return PRODUCTION;
  }
  if (host.includes("identity-token-service.ephemeral")) {
    return EPHEMERAL;
  }
  if (host.includes("stsstaging1")) {
    return STAGING1;
  }
  if (host.includes("stsstaging2")) {
    return STAGING2;
  }

  return LOCAL;
}

export const envIsDev = (): boolean => getReleaseStage() === LOCAL;
export const envIsStaging = (): boolean => {
  const stage = getReleaseStage();
  return stage === STAGING1 || stage === STAGING2;
};
export const envIsProduction = (): boolean => getReleaseStage() === PRODUCTION;
