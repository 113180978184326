import cookies from "js-cookie";

export const getCookie = (name: string) => {
  return cookies.get(name);
};

// same domain (.upstart.com) across UWF and UpstartWebTheme
// https://github.com/teamupstart/identity-token-service/blob/b38a65e0c239e8cf38d90ee0443aa41eac5a47a7/theme/web/theme/upstartWebTheme/login/resources/js/cookieHandler_v2.js#L20
// https://github.com/teamupstart/upstart-web-frontend/blob/643f396c7311818179ee7edc1f1c706935c45bd2/apps/top-of-the-funnel/src/utils/Cookies.ts#L65
export const setCookie = (name: string, value: string, minutes?: number) => {
  // If minutes is provided, use it to calculate the expiration time; otherwise, default to 5 minutes
  const expirationTime = minutes ? minutes * 60 * 1000 : 5 * 60 * 1000;
  const expires = new Date(new Date().getTime() + expirationTime);

  cookies.set(name, value, { expires, domain: ".upstart.com" });
};

export const deleteCookie = (name: string) => {
  cookies.remove(name, { path: "/" });
};
