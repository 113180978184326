import { datadogRum } from "@datadog/browser-rum";
import { ConsoleAnalytics, DatadogAnalytics, UpstartAnalytics } from "@upstart/analytics";
import { envIsDev, envIsProduction, envIsStaging } from "utils/getReleaseStage";

type EventName =
  | "identityTokenService_login_reset_password_click"
  | "identityTokenService_login_unlock_instructions_click"
  | "identityTokenService_login_confirmation_email_click"
  | "identityTokenService_contactCustomerSupport_click";

// Only run analytics on the Client (when window exists)
const destinations = envIsDev()
  ? [new ConsoleAnalytics()]
  : envIsProduction() || envIsStaging()
    ? [new DatadogAnalytics(datadogRum)]
    : [];

export const analytics = new UpstartAnalytics<EventName | string>(destinations);

export type TrackParameters = Parameters<(typeof analytics)["track"]>;
