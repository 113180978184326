import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import { AnalyticsProvider } from "utils/analytics";
import { envIsProduction, envIsStaging } from "utils/getReleaseStage";

// The following block can be uncommented to test a specific page with `yarn dev`
// Don't forget to comment back or your bundle size will increase
// TODO: custom ESlint rule to catch this if it is uncommented
/*
import { getKcContextMock } from "./login/KcPageStory";

if (process.env.NODE_ENV === "development") {
    window.kcContext = getKcContextMock({
        pageId: "login.ftl",
        overrides: {}
    });
}
*/

const KcLoginThemeApp = lazy(() => import("./keycloak-theme/login/KcApp"));
const DataDog = lazy(() => import("./keycloak-theme/components/molecules/vendor/Datadog"));

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense>
      <AnalyticsProvider pageId={window.kcContext?.pageId}>
        {(envIsProduction() || envIsStaging()) && <DataDog />}
        {(() => {
          if (window.kcContext?.themeType === "login") {
            return <KcLoginThemeApp kcContext={window.kcContext} />;
          }

          throw new Error(
            "This app is a Keycloak theme. It isn't meant to be deployed outside of Keycloak",
          );
        })()}
      </AnalyticsProvider>
    </Suspense>
  </StrictMode>,
);

declare global {
  interface Window {
    kcContext?: import("./keycloak-theme/login/kcContext").KcContext;
  }
}
