import React, { ReactNode, ReactElement, useEffect, useState } from "react";
import { OnError, useContextWebClientUuid } from "@upstart/analytics";
import { analytics, TrackParameters } from "./instance";
import { getCookie } from "utils/cookies";

export type Track = (...args: TrackParameters) => void;

const pageEventName = (pageId: string, event: PageEvent): string => {
  return `${pageId}-${event}`;
};

enum PageEvent {
  SUBMIT = "submit",
  ERROR = "error",
  VIEW = "view",
}

const AnalyticsContext = React.createContext<Track | undefined>(undefined);

export const PageEventWithCustomName = ({
  children,
  pageId,
}: {
  children: React.ReactNode;
  pageId?: string;
}): ReactElement => {
  const [lastPage, setLastPage] = useState("");
  const track = useTrackEvent();
  useEffect(() => {
    if (!track || !pageId || lastPage === pageId) {
      return;
    }
    setLastPage(lastPage);
    track(pageEventName(pageId, PageEvent.VIEW));
  }, [pageId, lastPage, track]);

  return <>{children}</>;
};

export const AnalyticsProvider = ({
  children,
  pageId,
}: {
  children?: ReactNode;
  pageId?: string;
}): React.ReactElement => {
  const webClientUuid = getCookie("upstart_uuid");

  const onError = React.useCallback<OnError>((message, properties) => {
    console.error(message, properties);
  }, []);

  useContextWebClientUuid({ analytics, onError, webClientUuid });

  return React.createElement(
    AnalyticsContext.Provider,
    {
      value: (...args) => {
        analytics.track(...args).catch(() => {
          onError("error tracking", { eventName: args[0], eventProperties: args[1] });
        });
      },
    },
    <PageEventWithCustomName pageId={pageId}>{children}</PageEventWithCustomName>,
  );
};

/**
 * An easier to consume version of `track`
 *
 * With `analytics.track`, the returned value is `Promise<string[]>`. With
 * our eslint rule of [no-floating-promises](https://typescript-eslint.io/rules/no-floating-promises/)
 * we would have to handle errors every time we called the function.
 *
 * By catching all of the promises internally within the `AnalyticsProvider`, we
 * can forgo the error handling externally.
 *
 * If prop drilling becomes an issue, use `analytics.track`.
 */
export function useTrackEvent(): Track {
  const state = React.useContext(AnalyticsContext);

  if (!state) {
    throw new Error("useTrackEvent must be a child of AnalyticsProvider");
  }

  return state;
}

/** Fire an analytics event exactly once. */
export function useTrackEventOnce(): Track {
  const track = useTrackEvent();
  const eventFiredRef = React.useRef<boolean>(false);

  let params: TrackParameters | undefined;

  React.useEffect(() => {
    if (params && !eventFiredRef.current) {
      eventFiredRef.current = true;
      track(...params);
    }
  }, [params, track]);

  return (name, properties) => {
    params = [name, properties];
  };
}
